import { forwardRef } from 'react';
import clsx from 'clsx';
import { faSearch } from '@fortawesome/pro-solid-svg-icons';
import { InputRef } from 'antd';
import TextField, { TextFieldProps } from '../TextField/TextField';

import classes from './TextSearchField.module.scss';

export type TextSearchFieldProps = Partial<TextFieldProps> & {
  className?: string;
  value: string | null;
  onChange: (newValue: string | null) => void;
  placeholder?: string;
  id?: string;
};

export default forwardRef<InputRef, TextSearchFieldProps>(function TextSearchField(
  { className, value, onChange, placeholder = 'Search...', id, ...rest }: TextSearchFieldProps,
  ref
) {
  return (
    <TextField
      onChange={onChange}
      value={value || ''}
      placeholder={placeholder}
      className={clsx(classes.textSearchField, className)}
      id={id}
      prefixFaIcon={faSearch}
      ref={ref}
      {...rest}
    />
  );
});
